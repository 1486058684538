<template>
  <auth-layout>
    <validation-observer ref="smsCheckForm">
      <app-mask-input
        input-mask="####"
        v-model="code"
        name="Sms Kodu"
        type="number"
        label="Sms Kodu:"
        placeholder="Kod Giriniz..."
        rules="required|min:4|max:4"
        @keyup.enter.native="checkCode"
      />
      <div class="d-flex justify-content-center">
        {{ timer }}
      </div>
      <hr />
      <app-button @click="checkCode" text="Onayla" block />
      <b-card-text class="text-center mt-2">
        <b-link @click="cancelCheck">
          <span>İptal Et</span>
        </b-link>
      </b-card-text>
    </validation-observer>
  </auth-layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthLayout from "./AuthLayout.vue";
export default {
  components: { AuthLayout, ValidationObserver },
  data() {
    return {
      code: null,
      timer: "00:00",
      countdown: null,
      distance: 180,
    };
  },
  computed: {
    smsCheck() {
      return this.$store.getters.smsCheck;
    },
  },
  methods: {
    cancelCheck() {
      this.$store.commit("setSmsCheck", null);
      this.$router.push({ name: "login" });
    },
    checkCode() {
      this.$validate(this.$refs.smsCheckForm, () => {
        this.$axios
          .post("/administrators/check-sms", { code: this.code }, { headers: { Authorization: this.smsCheck?.token }, loading: "table" })
          .then((response) => {
            const user = response.data;
            sessionStorage.setItem("token", user.token);
            this.$axios.defaults.headers.common.Authorization = user.token;
            this.$store.commit("setUser", user);
            delete user.token;
            sessionStorage.setItem("userData", JSON.stringify(user));
            this.$ability.update(user?.ability);
            this.$router.push({
              name: !user.first_login ? "first-login" : "dashboard",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
    startCountDown() {
      this.countdown = setInterval(() => {
        if (!this.distance || this.distance < 0) {
          clearInterval(this.countdown);
          this.timer = "00:00";
          this.$store.commit("cleanAppModal");
        } else {
          let minutes = Math.floor(this.distance / 60);
          let seconds = this.distance % 60;
          const timer = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
          this.timer = timer;
          this.distance -= 1;
        }
      }, 1000);
    },
  },
  mounted() {
    if (this.smsCheck) {
      const expireDate = new Date(this.smsCheck?.expires);
      const now = new Date();
      this.distance = expireDate > now ? Math.abs(expireDate.getTime() - now.getTime()) / 1000 : 0;
      this.distance = this.distance.toFixed(0);
      this.startCountDown();
    }
  },
  destroyed() {
    clearInterval(this.countdown);
  },
};
</script>

<style></style>
